import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'default-toolbar',
  templateUrl: './default-toolbar.component.html',
  styleUrls: ['./default-toolbar.component.less']
})
export class DefaultToolbarComponent implements OnInit {
  @Input()
  public minimal: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
