import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadingCount: number = 0;
    public status: string;
    public static injector: Injector = Injector.create([{ provide: LoadingService, deps: [] }, { provide: Location, deps: [] }]);

    public resetLoadingCount() {
        this.loadingCount = 0;
        this.loading.next(false);

    }
    public addLoadingCount() {
        this.loadingCount++;
        if (this.loadingCount > 0) {
            this.loading.next(true);
        }
    }

    public susLoadingCount() {
        if (this.loadingCount > 0) {
            this.loadingCount--;
            if (this.loadingCount <= 0) {
                this.loading.next(false);
            }
        } else {
            this.loading.next(false);
        }
    }
}