import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { SessionComponent } from './../../../common/components/session-component.component';

@Component({
	selector: 'parametricos',
	templateUrl: 'parametricos.component.html',
	styleUrls: ['parametricos.component.less']
})

export class ParametricosComponent extends SessionComponent implements OnInit {

	public parametros: ParamNav[] = [

	]
	constructor(private router: Router, messagesService: MessagesService) {
		super(messagesService);
	}
	ngOnInit() { }

	gotoParameter(item: ParamNav) {
		if (item.usarDefault) {
			let query: NavigationExtras = {
				queryParams: {
					"tipo": item.url
				}
			}
			this.router.navigate(["parametros"], { queryParams: { "tipo": item.url } });
		} else {
			this.router.navigate([item.url])
		}

	}
}

export class ParamNav {
	constructor(public label: string, public url: string, public icono?: string, public usarDefault: boolean = false) {

	}
}