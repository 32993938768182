
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
        exports: [MatFormFieldModule, MatInputModule, MatSelectModule,
                MatDatepickerModule, MatNativeDateModule,
                MatAutocompleteModule, MatIconModule,
                MatCheckboxModule, MatChipsModule, MatExpansionModule, DragDropModule],
        imports: [
                MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatExpansionModule
        ],
        providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }]
})
export class MaterialModule {
        constructor(public matIconRegistry: MatIconRegistry) {
                matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
        }
}