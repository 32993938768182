export class Registro {

    constructor(
        public nombre?: string,
        public apellido?: string,
        public email?: string,
        public password?: string
    ) {

    }
}