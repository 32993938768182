import { GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
export class DashboardConfiguration implements GridsterItem {
    [propName: string]: any;
    x: number;
    y: number;
    rows: number;
    cols: number;
    layerIndex?: number;
    initCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
    dragEnabled?: boolean;
    resizeEnabled?: boolean;
    compactEnabled?: boolean;
    maxItemRows?: number;
    minItemRows?: number;
    maxItemCols?: number;
    minItemCols?: number;
    minItemArea?: number;
    maxItemArea?: number;
    tipo: string;
    params?: any;
    fixedHeader?: boolean;


}
