import { Pipe, PipeTransform } from '@angular/core';
import { IHabilitable } from '../model/IHabilitable';

@Pipe({
  name: 'habilitados'
})
export class HabilitadosPipe implements PipeTransform {

  transform(value: IHabilitable[]): any {
    return value ? value.filter(h => h.habilitado) : [];
  }

}
