import { Injectable } from '@angular/core';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { CuentaContable } from 'src/app/model/CuentaContable';
@Injectable({
    providedIn: 'root',
})
export class CuentaContableService extends DescriptivosService<CuentaContable>{
    public baseName(): string {
        return "cuenta-contable";
    }
    public parseToEnt(data: any): CuentaContable {
        return CuentaContable.fromData(data);
    }
    public newEnt(): CuentaContable {
        return new CuentaContable();
    }


}