import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { CambioSaldoCaja } from 'src/app/model/CambioSaldoCaja';
@Injectable({
    providedIn: 'root',
})
export class CambioSaldoCajaService extends ServicioAbstract<CambioSaldoCaja>{
    public baseName(): string {
        return "cambio-saldo-caja";
    }
    public parseToEnt(data: any): CambioSaldoCaja {
        return CambioSaldoCaja.fromData(data);
    }
    public newEnt(): CambioSaldoCaja {
        return new CambioSaldoCaja();
    }


}